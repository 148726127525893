/* BASIC POPUP */
.popup {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
#popup__content__close {
  text-align: right;
}
#popup__content__close--button {
  background-color: #000;
  border: 2px solid #000;
  border-radius: 24px;
  font-size: 21px;
  color: #fff;
  padding: 0;
  max-width: 25px;
  max-height: 25px;
}
#popup__content__close--button:hover {
  background-color: #222;
  border: 2px solid #222;
}
.popup__content__options {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.4375rem 0.625rem -0.3125rem rgba(0, 187, 212, 0.4);
  padding: 24px;
  margin: -18px 10px;
}
.popup__content h3 {
  margin-bottom: 15px;
}
.popup__content__options button {
  width: 40%;
  padding: 10px;
  border-radius: 0.5em;
  border: 0;
  margin: 5px auto;
  font-size: 14px;
  font-weight: 600;
}
.popup__content button:hover {
  cursor: pointer;
}
.popup__content__button {
  width: 100%;
  display: flex;
}
#popup__content__button--yes {
  color: #fff;
  background-color: #e00001;
}
#popup__content__button--yes:hover {
  color: #fff;
  /* background: linear-gradient(195deg, #1A73E8, #0165e8); */
}
#popup__content__button--no {
  color: #e00001;
  background-color: #ddd;
}
#popup__content__button--no:hover {
  background-color: #bbb;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px 0;
  max-width: calc(100% - 20px); /* Ensure dropdown stays within viewport */
  overflow: visible; /* Make sure it expands without clipping */
  right: 1px; /* Shift the dropdown a little more to the right */
}

/* Styling for the dropdown container */
.column-visibility-dropdown {
  position: relative;
  display: inline-block;
  /* width: 100%;
  max-width: 250px; */
}

/* Style the dropdown button */
.dropdown-btn {
  background-color: #e00001;
  color: white;
  padding: 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px; /* Added margin for spacing */
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px 0;
  display: none; /* Ensure dropdown is hidden on page load */
  flex-direction: column; /* Stack the buttons vertically */
  align-items: flex-start; /* Align buttons to the start of the container */
  gap: 8px; /* Add space between buttons */
}

/* Style individual buttons inside the dropdown */
.dropdown-content .dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Add border between items */
}

/* Hover effect on the dropdown items */
.dropdown-content .dropdown-item:hover {
  background-color: #ddd; /* Add hover effect */
}

/* Style the active item to make it black */
.dropdown-content .dropdown-item.active {
  color: #000000; /* Set the text color to black */
  background-color: #000000; /* Optional: change the background color for better contrast */
}

/* Style for the active column visibility button */
button.dt-button.buttons-columnVisibility.dt-button-active {
  color: #000000; /* Change text color to black */
  /* background-color: #f1f1f1; */
  border: none; /* Remove the button border */
  text-decoration: none; /* Remove any text decoration */
  font-weight: 700; /* Remove any font-weight */
}

/* Optional: if you want to adjust the non-active button style */
button.dt-button.buttons-columnVisibility {
  background-color: transparent; /* Keep the background transparent for non-active buttons */
  color: #000; /* Set color to normal black */
  text-decoration: none; /* Remove any text decoration */
}

.dropdown-content .dropdown-item.hidden {
  background-color: #f44336;
  color: #ffffff; /* Set the text color to white */
}
