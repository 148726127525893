.action-checker__how-to-text {
  background-color: #fff;
  padding: 14px 28px 34px;
  border-radius: 5px;
  margin-bottom: 2%;
  box-shadow: #15223214 0px 1px 4px 0px;
}

.action-checker__how-to-text ol {
  line-height: 2em;
}

.action-checker__how-to-text--button-hightlight {
  background-color: rgb(224, 0, 1);
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
}

.action-checker__how-to-text__download-button {
  background-color: rgb(224, 0, 1);
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  padding: 10px 16px;
  border-radius: 4px;
  margin: 8px 12px 8px 0;
}

.action-checker__how-to-text__download-button:hover {
  background-color: rgb(156, 0, 0);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#successful_upload {
  color: #fff;
  background: #4bb543;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 20px;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-weight: 600;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.action-buttons > ul {
  justify-content: flex-start;
}

button.action-button {
  color: #fff;
  font-size: 1rem;
  padding: 6px 8px;
  min-width: 40px;
  background-color: rgb(186, 191, 211);
  border-radius: 4px;
  border: 0px;
}

button.action-button:hover {
  cursor: pointer;
  background-color: rgb(159, 164, 183);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.hide_button {
  display: none;
}

.row__status--success {
  color: #4bb543;
  font-weight: 600;
}

.row__status--fail {
  color: rgb(224, 0, 1);
  font-weight: 600;
}

.row__status--progress,
.row__status--queued,
.row__status--pending,
.row__status--working {
  color: rgb(98, 98, 98);
  font-weight: 600;
  font-style: italic;
}

/* .row__details--success {
} */

.row__details--fail {
  color: rgb(224, 0, 1);
}

.row__details--progress,
.row__details--queued,
.row__details--pending,
.row__details--working {
  color: rgb(98, 98, 98);
  font-style: italic;
}
