.add-activity-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.add-activity-popup__close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.add-activity-popup__inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 0;
}

.add-activity-popup__inputs > label {
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 12px 32px;
  font-size: 1.2rem;
  border-radius: 4px;
  background-color: rgb(224, 0, 1);
  margin-top: 24px;
  min-width: 300px;
}

.add-activity-popup__inputs > input {
  display: none;
}

.add-activity-popup__inputs--uploaded_file {
  color: #aaa;
  font-weight: 800;
  max-width: 300px;
  text-wrap: wrap;
}

.add-activity-popup__buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.uploadIcon {
  font-size: 4rem;
  color: rgb(224, 0, 1);
}

.error {
  color: rgb(255, 72, 72);
}
