ul.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 10px;
  list-style: none;
}

ul.pagination li {
  display: inline;
  margin: 0 2px;
}

ul.pagination li a {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
}

ul.pagination li.active a {
  background-color: #e00001;
  color: white;
}

ul.pagination li a:hover {
  background-color: #f1f1f1;
  color: #333;
}

.buttons-html5,
.buttons-print {
  background-color: #e00001;
  border-width: 0px;
  border-radius: 5px;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.d-flex.align-items-center.dt-controls {
  display: none;
}
