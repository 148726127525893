.file-input {
  position: relative;
  width: '100%';
  border: 2px solid #ccc;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border-color 0.3s;
}

.file-input label {
  font-weight: bold;
}

.file-input input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-input:hover {
  background-color: #f0f0f0;
  border-color: #aaa;
}
